import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Sentry from "@sentry/react";

import Loading from "../Components/Loading";
import { getHeaders } from "../Actions/actionCreator";
import {
  CheckoutLoadingWrapper,
  CheckoutWrapper,
  IconsRow,
  IconsWrapper,
  ImageTitle,
} from "../Styles/ViewStyles/CheckoutStyles";

import { ReactComponent as InsertCard } from "../Images/insert-card.svg";
import { ReactComponent as SwipeCard } from "../Images/swipe-card.svg";
import { ReactComponent as NFC } from "../Images/nfc.svg";
import { ReactComponent as ApplePay } from "../Images/applepay.svg";
import { ReactComponent as GPay } from "../Images/gpay.svg";
import CheckoutPaymentError from "../Components/CheckoutPaymentError";

const FIELD_HOST_RESPONSE = "1004";
const FIELD_HOST_RESPONSE_APPROVED = "APPROVAL";

const FIELD_GATEWAY_RESPONSE_MSG = "1010";
const FIELD_GATEWAY_RESPONSE_MSG_COMPLETE = "COMPLETE";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: { isOpened: false },
      loading: false,
      paymentMessage: "",
    };

    this.closeModal = () => {
      this.setState({ modal: { isOpened: false } });
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.sendInquiryMessage = this.sendInquiryMessage.bind(this);
    this.handleSendPaymentTransaction =
      this.handleSendPaymentTransaction.bind(this);
    this.handleSuccessMessage = this.handleSuccessMessage.bind(this);
  }

  handleSendPaymentTransaction() {
    const { orderId, amount, paymentWebSocket, currentProduct, cart } =
      this.props;
    const productName = currentProduct?.name || "";
    const field0412 = `;${cart.products.length || 0};${amount};`;

    const payload = {
      type: "payment",
      data: { orderId, amount, name: productName.slice(0, 40), field0412 },
    };

    paymentWebSocket?.handleSendPaymentMessage(JSON.stringify(payload));
  }

  async sendInquiryMessage(body, API, config) {
    const { idleTimer } = this.props;
    idleTimer.reset();
    idleTimer.pause();

    axios
      .post(`${API}/api/kiosk/transaction-inquiry`, body, config)
      .then((response) => {
        const {
          data: {
            data: { apiFields },
          },
        } = response;
        const hostMessage = apiFields.find(
          ({ fieldNumber }) => fieldNumber === FIELD_HOST_RESPONSE
        )?.fieldValue;
        const gatewayMsg = apiFields.find(
          ({ fieldNumber }) => fieldNumber === FIELD_GATEWAY_RESPONSE_MSG
        )?.fieldValue;
        const isSuccess =
          hostMessage === FIELD_HOST_RESPONSE_APPROVED &&
          gatewayMsg === FIELD_GATEWAY_RESPONSE_MSG_COMPLETE;
        if (isSuccess) {
          this.handleSuccessMessage();
        }
      })
      .catch((err) => {
        Sentry.captureMessage("Error running Inquiry request!");
        Sentry.captureException(err);
        this.setState({
          loading: false,
        });
      })
      .finally(() => {
        idleTimer.resume();
      });
  }

  handleSuccessMessage() {
    this.props.paymentWebSocket.resetMessage();
    this.props.history.push("/ZipCode");
  }

  handleCancel() {
    this.props.history.push("/");
  }

  componentDidMount() {
    this.handleSendPaymentTransaction();
  }

  componentDidUpdate(prevProps) {
    const {
      paymentWebSocket: { lastMessage },
    } = this.props;

    if (
      lastMessage &&
      prevProps?.paymentWebSocket?.lastMessage !== lastMessage
    ) {
      const { orderId, API, idleTimer, handleClose, client } = this.props;
      const config = getHeaders(client);
      config.headers = {
        ...config.headers,
        deviceData: btoa(JSON.stringify(lastMessage.deviceData)),
        Authorization: lastMessage.token,
      };

      const lines = lastMessage?.data?.split("\r\n");
      lines?.pop();

      const apiFields = lines?.map((line) => {
        const [fieldNumber, fieldValue] = line.split(",");

        return {
          fieldNumber: fieldNumber?.trim(),
          fieldValue: fieldValue?.trim(),
        };
      });

      const body = {
        saleRequestTransaction: {
          apiFields,
        },
      };

      if (lastMessage.message === "paymentCancellation") {
        this.handleCancel();
        return;
      }

      if (lastMessage.message === "paymentInquiry") {
        this.sendInquiryMessage(body, API, config);
        return;
      }

      handleClose();
      idleTimer.reset();
      idleTimer.pause();

      this.setState({ loading: true });

      axios
        .post(`${API}/api/kiosk/order/${orderId}/payment`, body, config)
        .then((response) => {
          const {
            data: { isSuccess },
          } = response;
          if (isSuccess) {
            this.handleSuccessMessage();
            return;
          }
          this.setState({
            modal: {
              isOpened: true,
              label: "Transaction declined. Please try again.",
            },
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            modal: {
              isOpened: true,
              label:
                err?.response?.data?.Code === 18
                  ? "Card reader error. Please swipe again!"
                  : "Authorization failed. Please try again.",
            },
            loading: false,
          });
        })
        .finally(() => {
          idleTimer.resume();
        });
    }
  }

  render() {
    const { client, isKioskAllowedToUse } = this.props;
    const { loading, modal } = this.state;
    if (!isKioskAllowedToUse) {
      return <Redirect exact push to={"/"} />;
    }

    return (
      <>
        {loading ? (
          <CheckoutLoadingWrapper>
            <Loading />
          </CheckoutLoadingWrapper>
        ) : (
          <>
            {modal.isOpened && (
              <CheckoutPaymentError
                client={client}
                cancelClick={this.handleCancel}
                retryClick={this.handleSendPaymentTransaction}
                title={modal.title}
              />
            )}
            <CheckoutWrapper client={client}>
              <h3>CHECK OUT</h3>
              <IconsWrapper>
                <ImageTitle>Tap, Insert or Swipe</ImageTitle>

                <IconsRow>
                  <NFC />
                  <InsertCard className="smaller" />
                  <SwipeCard className="smaller" />
                </IconsRow>

                <IconsRow center={true}>
                  <GPay />
                  <ApplePay />
                </IconsRow>
              </IconsWrapper>
            </CheckoutWrapper>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderId: state.orderStatus.orderId,
  isKioskAllowedToUse: state.isKioskAllowedToUse,
  amount: state.orderStatus.totalPrice,
  client: state.client,
  API: state.API,
  paymentWebSocket: state.paymentWebSocket,
  currentProduct: state.currentProduct,
  cart: state.cart,
});

export default withRouter(connect(mapStateToProps)(Checkout));
